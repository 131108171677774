@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@flaticon/flaticon-uicons/css/all/all";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html {
  width: 100%;
  height: 100%;
  /* background: #f0f2f5; */
}
#root {
  width: 100%;
  height: 100%;
}

body {
  @apply font-sans;
  @apply text-primaryText;
  width: 100%;
  height: 100%;
  margin: 0;
  color: '#092A41';
  font-family: 'Inter', sans-serif;
  /* Use Inter as the primary font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.01px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Minimal-width scrollbar */
.scrollbar-minimal {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: "#55758B" transparent;
  /* Thumb and track colors */
}

.scrollbar-minimal::-webkit-scrollbar {
  width: 2px;
  /* Width of the scrollbar for vertical scroll */
  height: 4px;
  /* Height of the scrollbar for horizontal scroll */
}

.scrollbar-minimal::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);
  /* Thumb color */
  border-radius: 8px;
  /* Rounded corners for thumb */
}

.scrollbar-minimal::-webkit-scrollbar-track {
  background: transparent;
  /* Transparent track */
}
